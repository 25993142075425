<template>
  <div v-if="$store.state.eric_records.length">
    <a-row gutter="16" justify="center">
      <a-col :span="24">
        <div v-for="record in $store.state.eric_records" :key="record.id" style="margin-bottom: 16px;">
          <a-card :title="record.title" bordered>
            <p v-if="record.author"><strong>Authors:</strong> {{ record.author.join(', ') }}</p>
            <p>{{ truncatedDescription(record.description) }}</p>
            <p v-if="record.url"><a :href="record.url" target="_blank"><a-tag color="blue"> Open Research Paper <i class='bx bx-link'></i></a-tag></a></p>
            <p><strong>Subjects:</strong> {{ record.subject.slice(0, 2).join(', ') }}</p>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
  <div v-else>
    No Research Paper Available...
  </div>
</template>

<script>
export default {
  data() {
    return {
      records: [
        {
          id: 'EJ1137388',
          title: 'The Effectiveness of Data Science as a Means to Achieve Proficiency in Scientific Literacy',
          author: ['Ceccucci, Wendy', 'Tamarkin, Dawn', 'Jones, Kiku'],
          description: 'Data Science courses are becoming more prevalent in recent years. Increasingly more universities are offering individual courses and majors in the field of Data Science. This study evaluates data science education as a means to become proficient in scientific literacy. The results demonstrate how the educational goals of a Data Science course meet the scientific literacy criteria in regards to the process of science. Based on the commonality between data science and scientific literacy courses, the paper concludes that a data science course can be used as an alternative way for students in any major to gain scientific literacy skills.',
          subject: ['Information Science', 'Higher Education', 'Scientific Literacy', 'Data Analysis', 'Data Collection', 'Science Process Skills', 'Scientific Methodology'],
          url: null,
        },
        {
          id: 'ED616783',
          title: 'What Is Data Science? An Operational Definition Based on Text Mining of Data Science Curricula',
          author: ['Zhang, Zhiyong', 'Zhang, Danyang'],
          description: 'Data science has maintained its popularity for about 20 years. This study adopts a bottom-up approach to understand what data science is by analyzing the descriptions of courses offered by the data science programs in the United States. Through topic modeling, 14 topics are identified from the current curricula of 56 data science programs. These topics reiterate that data science is at the intersection of statistics, computer science, and substantive fields.',
          subject: ['Statistics Education', 'Definitions', 'Course Descriptions', 'Computer Science Education', 'Information Security', 'Privacy', 'Ethics'],
          url: 'https://doi.org/10.35566/jbds/v1n1/p1',
        },
        {
          id: 'EJ1352131',
          title: 'Data Science in 2020: Computing, Curricula, and Challenges for the Next 10 Years',
          author: ['Schwab-McCoy, Aimee', 'Baker, Catherine M.', 'Gasper, Rebecca E.'],
          description: 'In the past 10 years, new data science courses and programs have proliferated at the collegiate level. As faculty and administrators enter the race to provide data science training and attract new students, the road map for teaching data science remains elusive. In 2019, 69 college and university faculty teaching data science courses and developing data science curricula were surveyed to learn about their curricula, computing tools, and challenges they face in their classrooms. Faculty reported teaching a variety of computing skills in introductory data science (albeit fewer computing topics than statistics topics), and that one of the biggest challenges they face is teaching computing to a diverse audience with varying preparation.',
          subject: ['Statistics Education', 'Higher Education', 'College Students', 'Teaching Methods', 'Computation', 'Data Analysis'],
          url: 'http://dx.doi.org/10.1080/10691898.2020.1851159',
        },
      ],
    };
  },
  created() {
    this.$highway.singleDispatcher('eircSearch');
  },
  methods: {
    truncatedDescription(description) {
      return description.length > 200 ? `${description.slice(0, 200)}...` : description;
    },
  },
};
</script>

<style>
a-card {
  margin-bottom: 16px;
}
</style>
